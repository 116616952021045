<template>
  <section class="page-bg">
    <div class="banner">
<!--      <p>英語発音評価システム</p>-->
      <div
          class="more-btn"
          @mouseenter="handleHover(true, 'engine')"
          @mouseleave="handleHover(false, 'engine')"
          @click="$router.push('/demo')">
        <div class="btn-text">
          <span ref="engineText1">Try demo</span>
          <span ref="engineText2">Try demo</span>
        </div>
        <div class="btn-image">
          <p ref="engineBtn1">
            <img src="~@/assets/images/index/see_more_active.png">
          </p>
          <p ref="engineBtn2">
            <img src="~@/assets/images/see_more.png">
          </p>
        </div>
      </div>
    </div>
    <div class="services page-container soe">
      <div class="services-intro">
        <h3>評価項目</h3>
        <p>
          ただの文字認識だけではなく、音響的に正確さ、流暢さ、完成度、トーン、
          <br>
          連続読み、ポーズ、イントネーションも採点結果項目になります。
        </p>
        <div>
          <div>
            <div>
              <p><img src="~@/assets/images/services/asr_e_1.png"></p>
              <span>文字認識</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services/asr_e_2.png"></p>
              <span>音韻＆音律評価</span>
            </div>
          </div>
        </div>
      </div>
      <div class="services-bg">
        <img src="~@/assets/images/services/tts_e.png">
      </div>
    </div>
    <div class="services tts page-container">
      <div class="services-bg">
        <img src="~@/assets/images/services/tts.png">
      </div>
      <div class="services-intro">
        <h3>評価発話種類</h3>
        <p>
          英語発話シーンの中に、簡単な音節、単語、段落発話だけではなく、もっと複雑的高いレベルの
          <br>
          クイズ回答、口頭作文、ルック・アンド・トークも評価できます。
        </p>
        <div>
          <div>
            <div>
              <p><img src="~@/assets/images/services/tts_e_1.png"></p>
              <span>基本読み評価</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services/tts_e_2.png"></p>
              <span>複雑作文評価</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="services dolphin page-container">
      <div class="services-intro">
        <h3>价格</h3>
        <p>
          料金体系は柔軟的に対応できます。オンラインはAPIの呼び出し回数、
          <br>
          オフラインはモバイルデバイスのインストール数それぞれ計算。
          <br>
          （詳細価格個別相談）
        </p>
        <div>
          <div>
            <div>
              <p><img src="~@/assets/images/services/nmt_1.png"></p>
              <span>オンライン<br>呼び出し回数</span>
            </div>
            <div>
              <p><img src="~@/assets/images/services/nmt_2.png"></p>
              <span>オフライン<br>デバイス数</span>
            </div>
          </div>
        </div>
      </div>
      <div class="services-bg">
        <img src="~@/assets/images/services/dolphin_ai.png">
      </div>
    </div>
    <div class="advantage">
      <h2 class="page-container">製品の優位性</h2>
      <div class="adv_intro page-container">
        <p>評価できる発話範囲広い</p>
        <p>幅広い採点項目</p>
        <p>精度高い発音矯正</p>
        <p>柔軟な採点メカニズム</p>
      </div>
      <div class="adv-details">
        <div class="details-left" ref="detailsLeft">
          <p><img ref="liImg" src="~@/assets/images/pro_name.png"></p>
          <ul id="ulist">
            <li ref="detailsLi" @click.prevent="advScroll($event)"><a id="firstLine_a" data-index="0">評価できる発話範囲広い</a></li>
            <li @click.prevent="advScroll($event)"><a id="secondLine_a" data-index="1">幅広い採点項目</a></li>
            <li @click.prevent="advScroll($event)"><a id="thirdLine_a" data-index="2">精度高い発音矯正</a></li>
            <li @click.prevent="advScroll($event)"><a id="forthLine_a" data-index="3">柔軟な採点メカニズム</a></li>
          </ul>
        </div>
        <div class="details-right">
          <el-timeline>
            <el-timeline-item id="firstLine" timestamp="評価できる発話範囲広い" placement="top">
              <el-card>
                <h4>読みレベルの音節＆担当＆段落と、もっと複雑な回答、長い作文、会話も評価できます。</h4>
                <div class="phs">
                  <p class="soe01"></p>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="secondLine" timestamp="幅広い採点項目" placement="top">
              <el-card>
                <h4>正確さ、流暢さ、完成度、トーン、速さのスコアなどを採点します。</h4>
                <div class="phs">
                  <p class="soe02"></p>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="thirdLine" timestamp="精度高い発音矯正" placement="top">
              <el-card>
                <h4>日本人英語発音にチューニングした評価エンジンで、音素単位を採点し、より精度の高い発音矯正します。</h4>
                <div class="phs">
                  <p class="soe03"></p>
                </div>
              </el-card>
            </el-timeline-item>
            <el-timeline-item id="forthLine" timestamp="柔軟な採点メカニズム" placement="top">
              <el-card>
                <h4>子供や大人など様々な年齢層のユーザーに対して、異なる採点メカニズムを提供します。</h4>
                <div class="phs">
                  <p class="soe04"></p>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
    </div>
    <div class="contact page-container" @mouseenter="handleHover(true)" @mouseleave="handleHover(false)">
      <div class="contact-center">
        <div class="contacts-btn" @click="$router.push('/contact')">協力相談</div>
        <p>お問い合わせいただければ幸いです</p>
      </div>

<!--      <div class="contact-left">-->
<!--        <h6>協力相談</h6>-->
<!--        <b ref="hoverEl"></b>-->
<!--        <p>お問い合わせいただければ幸いです</p>-->
<!--      </div>-->
<!--      <div class="contact-btn" @click="$router.push('/contact')">-->
<!--        <img src="~@/assets/images/services/contact.png">-->
<!--      </div>-->
    </div>
  </section>
</template>

<script>
export default {
  name: 'dolphin-ai',
  mounted () {
    window.onscroll = () => {
      this.pageScroll()
      this.pageScroll_li()
    }
    this.$nextTick(() => {
      let list = document.getElementById('ulist').children
      this.ulist = []
      for(let i = 0; i < list.length; i++) {
        this.ulist.push(list[i].innerText)
      }
    })
  },
  beforeDestroy () {
    window.onscroll = null
  },
  methods: {
    pageScroll () {
      let imgs = document.getElementsByClassName('services-bg')
      let h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      for (let i = 0; i < imgs.length; i++) {
        let img1Top = imgs[i].getBoundingClientRect().top
        if (img1Top < h-300) {
          imgs[i].classList.add("scroll-top")
          imgs[i].classList.remove("scroll-bottom")
        } else {
          imgs[i].classList.add("scroll-bottom")
          imgs[i].classList.remove("scroll-top")
        }
      }
    },
    // handleHover (bol) {
    //   if (bol) {
    //     this.$refs.hoverEl.classList.add('line-enter')
    //     this.$refs.hoverEl.classList.remove('line-leave')
    //   } else {
    //     this.$refs.hoverEl.classList.add('line-leave')
    //     this.$refs.hoverEl.classList.remove('line-enter')
    //   }
    // },
    handleHover (bol, refName) {
      let text1 = this.$refs[refName+'Text1']
      let text2 = this.$refs[refName+'Text2']
      let btn1 = this.$refs[refName+'Btn1']
      let btn2 = this.$refs[refName+'Btn2']
      if (bol) {
        text1.classList.add("text-move-enter")
        text1.classList.add("fade")
        text2.classList.add("text-move-enter")
        btn1.classList.add("btn-move-enter")
        btn2.classList.add("btn-move-enter")
        text1.classList.remove("text-move-leave")
        text2.classList.remove("text-move-leave")
        text2.classList.remove("fade")
        btn1.classList.remove("btn-move-leave")
        btn2.classList.remove("btn-move-leave")
      } else {
        text1.classList.add("text-move-leave")
        text2.classList.add("text-move-leave")
        text2.classList.add("fade")
        btn1.classList.add("btn-move-leave")
        btn2.classList.add("btn-move-leave")
        text1.classList.remove("text-move-enter")
        text1.classList.remove("fade")
        text2.classList.remove("text-move-enter")
        btn1.classList.remove("btn-move-enter")
        btn2.classList.remove("btn-move-enter")
      }
    },
    pageScroll_li () {
      let firstLine = document.getElementById('firstLine').getBoundingClientRect().top
      let firstLine_a = document.getElementById('firstLine_a').getBoundingClientRect().top
      let secondLine = document.getElementById('secondLine').getBoundingClientRect().top
      let secondLine_a = document.getElementById('secondLine_a').getBoundingClientRect().top
      let thirdLine = document.getElementById('thirdLine').getBoundingClientRect().top
      let thirdLine_a = document.getElementById('thirdLine_a').getBoundingClientRect().top
      let forthLine = document.getElementById('forthLine').getBoundingClientRect().top
      let forthLine_a = document.getElementById('forthLine_a').getBoundingClientRect().top
      let lis = document.getElementById('ulist').children
      if (firstLine_a-50 < firstLine&&firstLine < firstLine_a) {
        this.$refs.liImg.style.transform = `translateY(${lis[0].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
      if (firstLine_a < secondLine&&secondLine < thirdLine_a) {
        this.$refs.liImg.style.transform = `translateY(${lis[1].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
      if (secondLine_a < thirdLine&&thirdLine < forthLine_a) {
        this.$refs.liImg.style.transform = `translateY(${lis[2].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
      if (thirdLine_a < forthLine&&forthLine < forthLine_a+50) {
        this.$refs.liImg.style.transform = `translateY(${lis[3].offsetTop}px)`
        this.$refs.liImg.classList.add('move-left')
      }
    },
    advScroll (event) {
      let element = this.$refs.detailsLeft;
      let computedStyles = window.getComputedStyle(element);
      let detailsLi = this.$refs.detailsLi;
      let detailsLiStyles = window.getComputedStyle(detailsLi)
      let H = parseInt(detailsLiStyles.lineHeight.split('px')[0])+parseInt(detailsLiStyles.marginBottom.split('px')[0])
      let i = event.target.getAttribute('data-index')
      let list = document.getElementsByClassName('el-timeline-item')
      let targetPosition = list[i].offsetTop;
      let www = targetPosition-computedStyles.top.split('px')[0]-i*H
      window.scrollTo({
        top: www,
        behavior: "smooth"
      });
      this.pageScroll_li()
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  position: relative;
  background: url('../assets/images/services/soe_banner.jpg') no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  >p {
    font-size: 80px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 93.76px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }
  .more-btn {
    position: absolute;
    left: 380px;
    bottom: 100px;
    width: 175px;
    padding-left: 20px;
    padding-right: 20px;
    height: 54px;
    opacity: 1;
    border-radius: 20px;
    background: linear-gradient(225deg, rgba(87, 120, 255, 1) 0%, rgba(47, 41, 223, 1) 100%);
    cursor: pointer;
    cursor: hand;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-text {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      height: 18px;
      overflow: hidden;
    }
    span {
      display: inline-block;
      width: 100%;
      height: 18px;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 18px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
    }
    .btn-image {
      overflow: hidden;
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      display: flex;
      border-radius: 100%;
    }
    p, p>img {
      width: 22px;
      height: 22px;
      border-radius: 100%;
    }
    .btn-image >p:nth-of-type(1) {
      transform: translateX(-22px);
    }
    .btn-image >p:nth-of-type(2) {
      transform: translateX(-22px);
    }
    .btn-image .btn-move-enter {
      transform: translateX(0)!important;
      transition: transform 0.3s ease;
    }
    .btn-image .btn-move-leave {
      transform: translateX(-22px)!important;
      transition: transform 0.3s ease;
    }
  }
  .text-move-enter {
    transform: translateY(-18px);
    transition: transform 0.3s linear;
  }
  .text-move-leave {
    transform: translateY(0);
    transition: transform 0.3s linear;
  }
}
.services {
  min-height: 720px;
  background: url('../assets/images/services/services_bg.jpg') no-repeat left top;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.tts {
  background: url('../assets/images/services/tts_bg.png') no-repeat left top;
  background-size: 605px 760px;
  padding-left: 0;
  color: #000;
}
.services-intro {
  flex: 1;
  color: inherit;
  h3 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 46px;
    color: inherit;
    text-align: left;
    vertical-align: top;
    margin-bottom: 30px;
  }
  >p {

    font-size: 20px;
    letter-spacing: 0;
    line-height: 48px;
    color: inherit;
    text-align: left;
    vertical-align: top;
    margin-bottom: 50px;
  }
  >div >div {
    height: 128px;
    display: flex;
    >div {
      width: 274px;
      height: 80px;
      padding: 24px;
      border-radius: 27px;
      background: rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      margin-right: 20px;
      p, p>img {
        width: 80px;
        height: 80px;
      }
      span {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0;
        color: inherit;
        padding-left: 20px;
      }
    }
  }
}
.services-bg {
  width: 357px;
  height: 535px;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 90px;
  border-radius: 28px;
  img {
    width: 100%;
    height: 100%;
    transform: scale(1.2);
  }
}
.services-bg.scroll-top img {
  transform: scale(1);
  transition: transform 0.3s linear;
}
.services-bg.scroll-bottom img {
  transform: scale(1.2);
  transition: transform 0.3s linear;
}
.tts .services-bg {
  width: 546px;
  height: 445px;
  position: relative;
  left: -108px;
}
.tts .services-intro >div >p {
  background-image: url('../assets/images/pro_name.png')
}
.soe {
  padding-right: 0;
  .services-bg {
    border-radius: 30px 0 0 30px;
    width: 700px;
    height: auto;
    img {
      width: 700px;
      height: auto;
    }
  }
}
.dolphin {
  padding-right: 0;
  position: relative;
  .services-intro {
    width: 664px;
    >p {
      width: 630px;
    }
  }
}
.dolphin .services-bg {
  width: 600px;
  height: 442px;
  border-radius: 28px 0 0 28px;
  position: absolute;
  top: -38px;
  right: 0;
  overflow: hidden;
  img {
    width: 666px;
    height: 442px;
  }
}
.advantage {
  min-height: 300px;
  padding-top: 100px;
  padding-bottom: 100px;
  h2 {
    font-weight: 500;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 93.76px;
    color: rgba(47, 41, 223, 1);
    text-align: left;
    vertical-align: top;
    margin-bottom: 50px;
  }
  .adv_intro {
    height: 300px;
    margin-bottom: 102px;
    display: flex;
    p {
      width: 224px;
      height: 300px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 38px;
      padding-right: 38px;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 59px;
      color: rgba(60, 60, 60, 1);
      text-align: center;
      vertical-align: top;
      background: url('../assets/images/services/proAdv_1.png') no-repeat left top;
      background-size: 100% 100%;
      position: relative;
      right: -4px;
    }
    p:nth-of-type(2) {
      background-image: url('../assets/images/services/proAdv_2.png');
      right: 27px;
    }
    p:nth-of-type(3) {
      background-image: url('../assets/images/services/proAdv_3.png');
      left: -58px;
    }
    p:nth-of-type(4) {
      background-image: url('../assets/images/services/proAdv_4.png');
      left: -89px;
    }
  }
  .adv-details {
    min-height: 100px;
    display: flex;
    padding-left: 165px;
    .details-left {
      width: 300px;
      height: 160px;
      display: flex;
      flex-shrink: 0;
      margin-right: 40px;
      margin-left: 10px;
      position: sticky;
      top: 213px;
      >p, >p img {
        width: 16px;
        height: 16px;
      }
      >p {
        height: 160px;
        margin-right: 12px;
        padding-top: 4px;
      }
      ul li {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 25px;
        text-align: left;
        vertical-align: top;
        cursor: pointer;
      }
      ul li:nth-last-of-type(1) {
        margin-bottom: 0;
      }
    }
    .move-left {
      transition: transform 0.3s linear;
    }
    .details-right {
      flex: 1;
    }
  }
}
</style>
